import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// CSS file
import './resources/css/style.css'

// Components
import Navbar from './components/navbar';
import Content from './components/content';
import Footer from './components/footer';
import Privacy from './components/privacy';
import Terms from './components/terms';
import Error404 from './components/error404';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <Content />
        <Footer />
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
