import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import twitter from '../resources/img/twitter.svg'
import facebook from '../resources/img/facebook.svg'

class Footer extends Component {
    getYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <footer>
                <div class="container footer-nav">
                    <div class="col-xs-12 text-center">
                        <div>
                            <NavLink class="nav-link" to="/privacy" target="_blank">Privacy</NavLink>
                            <NavLink class="nav-link" to="/terms" target="_blank">Terms &amp; Conditions</NavLink>
                        </div>
                        <br />
                        <div>
                            <a class="nav-link" href="https://twitter.com/" target="_blank">
                                <img src={twitter} alt="Twitter" />
                            </a>
                            <a class="nav-link" href="https://facebook.com/" target="_blank">
                                <img src={facebook} alt="Facebook" />
                            </a>
                        </div>
                        <br />
                        <div id="copyright">
                            <span>© {this.getYear()} VendexApp.</span>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;