import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class Terms extends Component {
    render() {
        return (
            <div class="container">
                <Helmet>
                    <title>VendexApp : Terms &amp; Conditions</title>
                </Helmet>
                <article class="markdown-body entry-content" itemprop="text">
                    <h2>Terms &amp; Conditions</h2>
                    <p><strong>Acceptance of the Terms</strong></p>
                    <p>VendexApp website and its service (collectively known as the "Service") are offered to you by Vendex International ("Vendex") under the terms, conditions and notices stated herein. Your use and access of the Service constitutes your acceptance to all such terms, conditions, and notices.</p>

                    <p><strong>No Unlawful or Prohibited Use</strong></p>
                    <p>As a condition of your use of the Service, you warrant that you will not use Service for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Service in any manner which could damage, disable, overburden, or impair the Service or interfere with any other party's use of the Service. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Service.</p>

                    <p><strong>Exclusion of Liability</strong></p>
                    <p>Vendex shall in no event be liable for any damages, loss or expense including without limitation, direct, special or consequential damage or economic loss arising from or in connection with:</p>
                    <ul>
                        <li>any access, use or the inability to access or use the Service, or reliance on the materials and/or any information of the Service.</li>
                        <li>any system, server or connection failure, error, omission, interruption, delay in transmission, or computer virus.</li>
                        <li>any use of or access to any other website linked to this website.</li>
                    </ul>
                    <p>Vendex, to the fullest extent permitted by law, disclaims all warranties, whether express or implied, including the warranty of merchantability, fitness for particular purpose and non-infringement.</p>

                    <p><strong>Indemnity</strong></p>
                    <p>You agree to defend, indemnify and hold harmless Vendex from and against any claims, actions or demands, alleging or resulting from your use of the Service or your breach of the terms of this agreement.</p>

                    <p><strong>Copyright and Trademarks</strong></p>
                    <p>The contents of the Service, such as text, graphics, images and other material, are protected by copyright. The names of actual companies and products mentioned in the Service may be the trademarks of their respective owners. Any rights not expressly granted herein are reserved.</p>

                    <p><strong>Other Content</strong></p>
                    <p>The Service may contain links to other websites, which are not under the control of Vendex and Vendex is not responsible for the contents of any linked websites, including without limitation any link contained in a linked website, or any changes or updates to a linked website. Any links to any other websites are provided as a convenience to you and not an endorsement or verification of such websites and such websites should be accessed at the user's own risk.</p>

                    <p><strong>Governing Law and Others</strong></p>
                    <p>This Agreement is governed by the laws of Qatar.</p>
                    <p>The Terms of Use may have been translated into other languages. In the event of inconsistency or discrepancy between the English version and any other language version of these Terms of Use, the English language version shall prevail.</p>
                </article>
                <br />
            </div>
        );
    }
}

export default Terms;