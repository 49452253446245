import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../resources/img/logo-name@2x.png'

class Navbar extends Component {
    render() {
        return (
            <nav class="navbar navbar-default invert-buttons">
                <div class="container">
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#navbar-collapse-1" aria-expanded="false">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <a class="navbar-brand" href="#">
                            <img alt="VendexApp" src={logo} class="app-icon" />
                        </a>
                    </div>
                    <div class="collapse navbar-collapse" id="navbar-collapse-1">
                        <ul class="nav navbar-nav navbar-right">
                            <li><a href="https://facebook.com/" target="_blank">Facebook</a></li>
                            <li><a href="https://twitter.com/" target="_blank">Twitter</a></li>
                            <li><a href="https://vendexintlco.com/vending_machine_locations/" target="_blank"><b>Our Locations</b></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;