import React, { Component } from 'react';
import appstore from '../resources/img/appstore.svg'
import googlestore from '../resources/img/google-play-badge.png'
import dualscreen from '../resources/img/dual-screen@2x.png'
import dualscreensmall from '../resources/img/dual-screen-small@2x.png'

import phone1 from '../resources/img/phone-1@2x.png'
import phone2 from '../resources/img/phone-2@2x.png'
import phone3 from '../resources/img/phone-3@2x.png'
import phone4 from '../resources/img/phone-4@2x.png'
import phone5 from '../resources/img/phone-5@2x.png'

class Content extends Component {
    render() {
        return (
            <div>
                <div class="container top-section">
                    <div class="row">
                        <div class="col-xs-12">
                            <h1 id="title">Vendex App</h1>
                            <p id="subtitle">Your convenience way of making a payment!</p>
                            <div>
                                <a class="nav-link" href="https://apps.apple.com/us/app/id1533616272"
                                    target="_blank"><img id="app-store" src={appstore}
                                        alt="Downlaod on the App Store" /></a>
                                <a class="nav-link" href="https://play.google.com/store/apps/details?id=com.vendex.nxpay"
                                    target="_blank"><img id="app-store" src={googlestore}
                                        alt="Get it on Google Play" /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container dual-screen">
                    <div class="row">
                        <img class="bigger" src={dualscreen} alt="App Mockup Screens" />
                        <img class="smaller" src={dualscreensmall} alt="App Mockup Screens" />
                    </div>
                </div>

                <div class="container features">
                    <div class="row">
                        <div class="col-xs-12 feature-line"></div>
                    </div>
                    <div class="row feature-row">
                        <div class="col-xs-12 col-sm-5 description">
                            <h2>Easy signup</h2>
                            <hr class="separator blue" />
                            <p>It just takes minutes to signup. Easy for you and easy for us.</p>
                        </div>
                        <div class="col-xs-12 col-sm-offset-1 col-sm-6 screen">
                            <img src={phone1} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 feature-line"></div>
                    </div>
                    <div class="row feature-row">
                        <div class="col-xs-12 col-sm-5 col-sm-push-7 description">
                            <h2>Informative and clean UI</h2>
                            <hr class="separator blue" />
                            <p>Easily accessible action within a single click. Make a payment, just a click away.</p>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-sm-pull-5 screen">
                            <img src={phone2} alt="historical summary" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 feature-line"></div>
                    </div>
                    <div class="row feature-row">
                        <div class="col-xs-12 col-sm-5 description">
                            <h2>Track your purchase records</h2>
                            <hr class="separator blue" />
                            <p>View your reload and purchase history at your convenience.</p>
                        </div>
                        <div class="col-xs-12 col-sm-offset-1 col-sm-6 screen">
                            <img src={phone3} alt="gesture and touch" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 feature-line"></div>
                    </div>
                    <div class="row feature-row">
                        <div class="col-xs-12 col-sm-5 col-sm-push-7 description">
                            <h2>Show QR to receive money</h2>
                            <hr class="separator blue" />
                            <p>You can request money from your friends by simply letting them scan your QR code.</p>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-sm-pull-5 screen">
                            <img src={phone4} alt="habit progress" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 feature-line"></div>
                    </div>
                    <div class="row feature-row">
                        <div class="col-xs-12 col-sm-5 description">
                            <h2>Touchless features</h2>
                            <hr class="separator blue" />
                            <p>Select product directly from app to the vending machine.</p>
                        </div>
                        <div class="col-xs-12 col-sm-offset-1 col-sm-6 screen">
                            <img src={phone5} alt="hassle free tracking" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 feature-line"></div>
                    </div>
                </div>
                <div class="container comments">
                    <div class="row">
                        <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                            <div class="comment">
                                <h3>Brings you to the future path of eWallet. Experience it now.</h3>
                            </div>
                            <p class="commentor">- VendexApp Team</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;