import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class Privacy extends Component {
    render() {
        return (
            <div class="container">
                <Helmet>
                    <title>VendexApp : Privacy Policy</title>
                </Helmet>
                <article class="markdown-body entry-content" itemprop="text">
                    <h2>Privacy Policy</h2>
                    <p>We are committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Privacy Policy applies to the VendexApp website and its services (collectively known as the "Service") and governs data collection and usage. By using the Service, you consent to the data practices described in this policy.</p>

                    <p><strong>Collection of your Personal Information</strong></p>
                    <p>The Service collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. The Service also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favourites.</p>
                    <p>There is also information about your computer hardware and software that is automatically collected by the Service. This information can include your IP address, browser type, domain names, access times and referring Web site addresses. This information is used by the Service for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Service.</p>
                    <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data publicly, this information may be collected and used by others.</p>

                    <p><strong>Use of your Personal Information</strong></p>
                    <p>The Service collects and uses your personal information to operate the Service and deliver the services you have requested. The Service also uses your personally identifiable information to inform you of other products or services available from VendexApp and its affiliates. VendexApp may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.</p>
                    <p>The Service does not sell, rent or lease its customer lists to third parties. VendexApp may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, VendexApp may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to VendexApp, and they are required to maintain the confidentiality of your information.</p>
                    <p>The Service does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.</p>
                    <p>The Service keeps track of the web sites and pages our customers visit within the Service, in order to determine what services are the most popular. This data is used to deliver customized content and advertising within the Service to customers whose behaviour indicates that they are interested in a particular subject area.</p>
                    <p>The Service will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to (a) conform to the edicts of the law or comply with legal process served on the Service; (b) protect and defend the rights or property of VendexApp; and, (c) act under exigent circumstances to protect the personal safety of users of the Service, or the public.</p>

                    <p><strong>Use of Cookies</strong></p>
                    <p>The Service uses "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
                    <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same web site, the information you previously provided can be retrieved, so you can easily use the Service features that you customized.</p>
                    <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Service.</p>

                    <p><strong>Security of your Personal Information</strong></p>
                    <p>VendexApp secures your personal information from unauthorized access, use or disclosure. VendexApp secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>

                    <p><strong>Anti-Spam</strong></p>
                    <p>The Service only sends emails and messages to the users based on their preference and only emails and messages with relevant information will be sent. Moreover, for all users who use the Service, their contact information such as email addresses and mobile phone numbers are not simply disclosed to any other users.</p>

                    <p><strong>Changes to this Statement</strong></p>
                    <p>This Privacy Policy might be occasionally updated to reflect company and customer feedback. VendexApp encourages you to periodically review this policy to be informed of how VendexApp is protecting your information.</p>
                </article>
                <br />
            </div>
        );
    }
}

export default Privacy;