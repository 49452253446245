import React, { Component } from 'react';

class Error404 extends Component {
    render() {
        return (
            <div>
                Page not found
            </div>
        );
    }
}

export default Error404;
